import SocialIcon from "../elements/social/SocialIcon";

// List
// list =[{platform: "facebook", link: "https://facebook.com"}, {platform: "twitter", link: "https://twitter.com"}]

const SocialIconList = ({ list, size = 20 }) => {
  return (
    <div className={`flex ${size >= 20 ? "gap-4" : "gap-2"}`}>
      {list.map((item) => (
        <SocialIcon
          active={Boolean(item.link)}
          key={item.link || Math.random()}
          platform={item.platform}
          link={item.link}
          size={size}
        />
      ))}
    </div>
  );
};

export default SocialIconList;
