export const socialList = [
  {
    platform: "facebook",
    link: "https://www.facebook.com/agrowyglobal",
  },
  {
    platform: "instagram",
    link: "https://www.instagram.com/agrowyglobal",
  },
  {
    platform: "youtube",
    link: "https://www.youtube.com/channel/UCqINrGV1W4KY00N3k87O2EQ",
  },
  {
    platform: "linkedin",
    link: "https://www.linkedin.com/company/agrowyglobal",
  },
  {
    platform: "twitter",
    link: "https://www.twitter.com/agrowyglobal",
  },
];

export const footerLinks = [
  {
    tr: [
      {
        title: "Bitki Besleme",
        link: "/tarim-urunleri/bitki-besleme",
      },
      {
        title: "Bitki Koruma",
        link: "/tarim-urunleri/bitki-koruma",
      },
      {
        title: "Tohum, Fide, Fidan",
        link: "/tarim-urunleri/tohum-fide-fidan",
      },
      {
        title: "Sulama Sistemleri",
        link: "/tarim-urunleri/sulama-sistemleri",
      },
      {
        title: "Sera ve Ekipmanları",
        link: "/tarim-urunleri/sera-ve-ekipmanlari",
      },
    ],
    en: [
      {
        title: "Plant Nutrition",
        link: "/en/tarim-urunleri/plant-nutrition",
      },
      {
        title: "Plant Protection",
        link: "/en/tarim-urunleri/plant-protection",
      },
      {
        title: "Seed, Seedling and Sapling",
        link: "/en/tarim-urunleri/seed-seedling-sapling",
      },
      {
        title: "Irrigation Systems",
        link: "/en/tarim-urunleri/irrigation-systems",
      },
      {
        title: "Greenhouse and Equipments",
        link: "/en/tarim-urunleri/greenhouse-and-equipments",
      },
    ],
    es: [
      {
        title: "Nutrición Vegetal",
        link: "/es/tarim-urunleri/nutricion-vegetal",
      },
      {
        title: "Plan de Preteccion",
        link: "/es/tarim-urunleri/plan-de-proteccion",
      },
      {
        title: "Semillas y Plántulas",
        link: "/es/tarim-urunleri/semilla-plantula-retono",
      },
      {
        title: "Sistemas de Riego",
        link: "/es/tarim-urunleri/sistemas-de-riego",
      },
      {
        title: "Invernadero y Equipamiento",
        link: "/es/tarim-urunleri/invernadero-y-equipamiento",
      },
    ],
    ru: [
      {
        title: "Питание растений",
        link: "/ru/tarim-urunleri/pitanie-rasteniy",
      },
      {
        title: "Защита растений",
        link: "/ru/tarim-urunleri/zaschita-rasteniy",
      },
      {
        title: "Семена, Саженец, Саженец",
        link: "/ru/tarim-urunleri/semena-sazhenets-sazhenets",
      },
      {
        title: "Системы орошения",
        link: "/ru/tarim-urunleri/sistemy-orosheniya",
      },
      {
        title: "Теплица и оборудование",
        link: "/ru/tarim-urunleri/teplitsa-i-oborudovanie",
      },
    ],
  },

  [
    {
      title: "Ürünler",
      link: "/tarim-urunleri",
    },
    {
      title: "Tarım Şirketleri",
      link: "/tarim-sirketleri",
    },
    {
      title: "Fuar Takvimi 2023",
      link: "/tarim-fuarlari-takvimi",
    },
  ],
  [
    {
      title: "Zirai Mücadele",
      link: "/zirai-mucadele-kutuphanesi",
    },
    {
      title: "Yetiştiricilik Kütüphanesi",
      link: "/yetistiricilik-kutuphanesi",
    },
  ],
  [
    {
      title: "Agrowy Nedir?",
      link: "/agrowy-nedir",
    },
    {
      title: "İletişim",
      link: "/iletisim",
    },
    {
      title: "KVKK Aydınlatma Metni",
      link: "/kvkk-kapsaminda-aydinlatma-metni",
    },
    {
      title: "Çerez Politikası",
      link: "/cerez-politikasi",
    },
  ],
];

// Imported in getBlogPostContent

export const languagesCodes = {
  en: "en-US",
  ru: "ru-RU",
  es: "es-ES",
};

// Imported in getBlogPostContent

export const folderNames = {
  en: {
    post: "posts",
  },
  ru: {
    post: "soobsenija",
  },
  es: {
    post: "publicaciones",
  },
};

// Imported middlewares
export const authCookieName =
  process.env.NODE_ENV === "development"
    ? "next-auth.session-token"
    : "__Secure-next-auth.session-token";

// Imported in getProductListing... (lib)
export const mainCategoryList = {
  tr: [
    {
      title: "Bitki Koruma",
      slug: "bitki-koruma",
    },
    {
      title: "Bitki Besleme",
      slug: "bitki-besleme",
    },
    {
      title: "Tohum, Fide, Fidan",
      slug: "tohum-fide-fidan",
    },
    {
      title: "Sera Ekimpanları",
      slug: "sera-ve-ekipmanlari",
    },
    {
      title: "Sulama Sistemleri",
      slug: "sulama-sistemleri",
    },
  ],
  en: [
    {
      title: "Plant Protection",
      slug: "plant-protection",
    },
    {
      title: "Plant Nutrition",
      slug: "plant-nutrition",
    },
    {
      title: "Seed, Seedling and Sapling",
      slug: "seed-seedling-sapling",
    },
    {
      title: "Greenhouse and Equipments",
      slug: "greenhouse-and-equipments",
    },
    {
      title: "Irrigation Systems",
      slug: "irrigation-systems",
    },
  ],
  ru: [
    {
      title: "Защита растений",
      slug: "zaschita-rasteniy",
    },
    {
      title: "Питание растений",
      slug: "pitanie-rasteniy",
    },
    {
      title: "Семена, Саженец, Саженец",
      slug: "semena-sazhenets-sazhenets",
    },
    {
      title: "Теплица и оборудование",
      slug: "teplitsa-i-oborudovanie",
    },
    {
      title: "Системы орошения",
      slug: "sistemy-orosheniya",
    },
  ],
  es: [
    {
      title: "Plan de Proteccion",
      slug: "plan-de-proteccion",
    },
    {
      title: "Nutrición Vegetal",
      slug: "nutricion-vegetal",
    },
    {
      title: "Semilla, plántula, retoño",
      slug: "semilla-plantula-retono",
    },
    {
      title: "Invernadero y Equipamiento",
      slug: "invernadero-y-equipamiento",
    },
    {
      title: "Sistemas de Riego",
      slug: "sistemas-de-riego",
    },
  ],
};
