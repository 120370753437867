import Link from "next/link";

const TextLink = (props) => {
  const { colored = true, sm = true, wrap = false } = props;
  return (
    <Link
      prefetch={false}
      href={props.link}
      className={`${sm ? "text-sm" : "text-base"} ${
        colored ? "text-secondaryDark" : null
      } ${
        wrap ? " whitespace-pre-wrap" : "whitespace-nowrap"
      }  hover:text-primaryLight duration-100 ease-out transition-colors`}
    >
      {props.children}
    </Link>
  );
};

export default TextLink;
