import Footer from "../components/modules/footer/Footer";
import Navigation from "../components/modules/navigation/Navigation";
import useHeadComponent from "../hooks/useHeadComponent";
import axios from "axios";
import dynamic from "next/dynamic";

const HomeTR = dynamic(() => import("../components/modules/home/HomeTR"));
const HomeInt = dynamic(() => import("../components/modules/home/HomeInt"));
export default function Home({
  sliderData,
  feedData,
  popularPostsData,
  locale,
  featuredProducts,
}) {
  const localeLinks = {
    en: "/en",
    ru: "/ru",
    es: "/es",
    tr: "/",
  };

  const Head = useHeadComponent({
    title: "Agrowy - Çözüm Odaklı Dijital Tarım Platformu",
    translationKey: "home",
    description:
      "Tarım ürünlerine ve şirketlere, yurtiçi ve yurtdışı tarım fuarlarına, zirai mücadele yöntemlerine, yetiştiricilik hakkında binlerce içeriğe kolayca ulaş.",
    alternateList: localeLinks,
    dynamic: false,
    noindex: false,
    noBrand: true,
    ogImage: null,
    ogType: "website",
    schemaMarkup: null,
  });

  return (
    <div className="">
      <Navigation localeLinks={localeLinks} />
      {/* Get Homepage Based on Locale */}
      {locale === "tr" ? (
        <HomeTR
          popularPostsData={popularPostsData}
          initialFeedData={feedData}
          sliderData={sliderData}
        />
      ) : (
        <HomeInt
          featuredProducts={featuredProducts}
          locale={locale}
          sliderData={sliderData}
        />
      )}
      <Footer />
      {Head}
    </div>
  );
}

export async function getServerSideProps({ locale }) {
  // Slider Fetcher
  const companiesFetcher = async () => {
    try {
      const { data } = await axios.get(
        process.env.NEXT_PUBLIC_DIRECTUS_URL +
          "/items/companies?fields=id,title,slug,slider_image,score&filter[active][_eq]=true&filter[slider_image][_nnull]=true&filter[status][_eq]=published"
      );
      return data.data;
    } catch (error) {
      console.log(error.response);
      return error;
    }
  };

  // Feed Fetcher
  const fetcher = async () => {
    try {
      const { data } = await axios.get(
        process.env.NEXT_PUBLIC_DIRECTUS_URL + "/feed?page=0"
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  // Popular Posts Fetcher
  const popularPosts = async () => {
    try {
      const { data } = await axios.get(
        process.env.NEXT_PUBLIC_DIRECTUS_URL +
          `/items/posts?limit=4&sort=-page_view&fields=title,slug,tags.categories_id.title,date_created,id&filter[status][_eq]=published`
      );
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Featured Products Fetcher
  const featuredProductsFetcher = async () => {
    try {
      const { data } = await axios.get(
        process.env.NEXT_PUBLIC_DIRECTUS_URL +
          "/items/products?fields=title,id,sort,slug,score,mini_image,company.title,company.score,company.id,subcategory.title,category.title,subcategory.translations.title,subcategory.translations.languages_code,translations.title,translations.languages_code&filter[status][_eq]=published&filter[active][_eq]=true&sort=sort&limit=12"
      );
      return data.data;
    } catch (error) {
      console.log(error.response);
      return error;
    }
  };

  if (locale === "tr") {
    const feedData = fetcher();
    const popularPostsData = await popularPosts();
    const sliderData = companiesFetcher().then((data) =>
      data.sort(() => (Math.round(Math.random() * 10) > 4 ? 1 : -1))
    );

    return {
      props: {
        sliderData: await sliderData,
        feedData: await feedData,
        popularPostsData: popularPostsData,
        locale,
      },
    };
  } else {
    const featuredProducts = featuredProductsFetcher();
    const sliderData = companiesFetcher().then((data) =>
      data.sort(() => (Math.round(Math.random() * 10) > 4 ? 1 : -1))
    );
    return {
      props: {
        sliderData: await sliderData,
        featuredProducts: await featuredProducts,
        locale,
      },
    };
  }
}
