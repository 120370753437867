import supabase from "../supabase/client";
import cookieCutter from "cookie-cutter";
import { signOut } from "next-auth/react";

const signOutFunction = () => {
  supabase.auth.signOut();
  signOut();
  cookieCutter.set("hortiturkey_refresh", "", { expires: new Date(0) });
};

export default signOutFunction;
