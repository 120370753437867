import TextLink from "../../elements/buttons/TextLink";

const FootlerLinkList = ({ links, title }) => {
  return (
    <div>
      <div className="mb-4 text-sm font-medium text-gray-400">{title}</div>
      <div className="flex flex-col gap-5">
        {links.map((link) => {
          return (
            <div key={link.link}>
              <TextLink wrap colored={false} sm={true} link={link.link}>
                {link.title}
              </TextLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FootlerLinkList;
