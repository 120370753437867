import dynamic from "next/dynamic";
import Link from "next/link";

const FacebookFillIcon = dynamic(() =>
  import("akar-icons").then((mod) => ({
    default: (props) => <mod.FacebookFill {...props} />,
  }))
);
const LinkedinFillIcon = dynamic(() =>
  import("akar-icons").then((mod) => ({
    default: (props) => <mod.LinkedinFill {...props} />,
  }))
);
const InstagramFillIcon = dynamic(() =>
  import("akar-icons").then((mod) => ({
    default: (props) => <mod.InstagramFill {...props} />,
  }))
);
const YoutubeFillIcon = dynamic(() =>
  import("akar-icons").then((mod) => ({
    default: (props) => <mod.YoutubeFill {...props} />,
  }))
);
const TwitterFillIcon = dynamic(() =>
  import("akar-icons").then((mod) => ({
    default: (props) => <mod.TwitterFill {...props} />,
  }))
);

const SocialIcon = ({ link, platform, active, size }) => {
  let icon;

  switch (platform) {
    case "facebook":
      icon = <FacebookFillIcon size={size} />;
      break;
    case "instagram":
      icon = <InstagramFillIcon size={size} />;
      break;
    case "linkedin":
      icon = <LinkedinFillIcon size={size} />;
      break;
    case "twitter":
      icon = <TwitterFillIcon size={size} />;
      break;
    case "youtube":
      icon = <YoutubeFillIcon size={size} />;
  }

  if (active) {
    return (
      <Link
        target="_blank"
        href={link}
        className="transition-colors duration-100 ease-out hover:text-primaryLight text-neutral-800"
      >
        {icon}
      </Link>
    );
  } else {
    return <span className="text-neutral-300">{icon}</span>;
  }
};

export default SocialIcon;
