import PrimaryGreenButton from "../buttons/PrimaryGreenButton";
import { chatBoxPopupAtom } from "../ui/ChatBoxPopup";
import { useAtom } from "jotai";

const MiniCtaBox = ({ title, description, buttonTitle }) => {
  const [, setChatBox] = useAtom(chatBoxPopupAtom);
  return (
    <div className="p-6 border border-gray-100 rounded-md ">
      <div className="text-xl font-medium">{title}</div>
      <p className="mt-2 mb-5 text-base text-neutral-600">{description}</p>
      <PrimaryGreenButton
        handler={() => {
          setChatBox((prev) => {
            return { ctaMessage: false, show: !prev.show, opened: true };
          });
        }}
        type="button"
      >
        {buttonTitle}
      </PrimaryGreenButton>
    </div>
  );
};

export default MiniCtaBox;
