import { footerLinks, socialList } from "../../../constants/hortiturkey";
import getTranslatedText from "../../../libs/getTranslatedText";
import getTranslation from "../../../libs/getTranslations";
import signOutFunction from "../../../libs/signOutFunction";
import LogoBlack from "../../elements/brand/LogoBlack";
import MiniCtaBox from "../../elements/ui/MiniCtaBox";
import RegularContainer from "../../layouts/RegularContainer";
import SocialIconList from "../SocialIconList";
import FootlerLinkList from "./FootlerLinkList";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

const Footer = ({ productPage, loading }) => {
  const { locale } = useRouter();
  const t = useMemo(() => {
    return getTranslation(locale);
  }, [locale]);

  return (
    <footer
      className={`px-6 border-t border-t-primaryLight ${
        productPage && loading ? "mt-[700px]" : ""
      }`}
    >
      <RegularContainer>
        <div className="grid w-full grid-cols-1 gap-6 py-20 border-b gap-y-10 sm:grid-cols-6 md:grid-cols-12 border-b-gray-200">
          <div className={locale === "tr" ? "col-span-4" : "col-span-3"}>
            <Link prefetch={false} passHref href="/" onClick={signOutFunction}>
              <LogoBlack />
            </Link>
            <div className="text-xl font-light py-9 max-w-[280px]">
              {locale === "tr" ? (
                <>
                  Tarım sektöründeki{" "}
                  <span className="text-primaryLight font-regular">
                    tüm paydaşları bir araya getirerek{" "}
                  </span>
                  büyümelerine katkı sağlıyoruz.
                </>
              ) : (
                getTranslatedText(
                  locale,
                  "Tarım sektöründeki tüm paydaşları bir araya getirerek büyümelerine katkı sağlıyoruz.",
                  "footer.slogan"
                )
              )}
            </div>
            <SocialIconList list={socialList} />
          </div>
          <div className="col-span-2">
            <FootlerLinkList
              links={footerLinks[0][locale]}
              title={locale === "tr" ? "Ürünler" : t.footer.footerTitles.title1}
            />
          </div>
          <div className="col-span-2">
            <FootlerLinkList
              links={locale === "tr" ? footerLinks[1] : t.footer.footerLinks[1]}
              title={
                locale === "tr"
                  ? "Hızlı Bağlantılar"
                  : t.footer.footerTitles.title2
              }
            />
          </div>
          <div className="col-span-2">
            <FootlerLinkList
              links={locale === "tr" ? footerLinks[2] : t.footer.footerLinks[2]}
              title={locale === "tr" ? "Öğren" : t.footer.footerTitles.title3}
            />
          </div>
          <div className={locale === "tr" ? "col-span-2" : "col-span-3"}>
            {locale === "tr" ? (
              <FootlerLinkList links={footerLinks[3]} title={"Şirket"} />
            ) : (
              <MiniCtaBox
                title={t.footer.miniCtaBox.heading}
                description={t.footer.miniCtaBox.description}
                buttonTitle={t.footer.miniCtaBox.buttonTitle}
                link={t.footer.miniCtaBox.link}
              />
            )}
          </div>
        </div>
        <div className="my-8 text-xs font-medium text-gray-400">
          {`© ${new Date().getFullYear()} ${
            locale === "tr"
              ? "Tüm hakları gizlidir. agrowy"
              : t.footer.copyrightText
          }`}
        </div>
      </RegularContainer>
    </footer>
  );
};

export default Footer;
